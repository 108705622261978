<script>
    import { writable, get } from 'svelte/store';
    export let selectedExercise;
    export let existingData = null;
    import {userStore, trackContentUpload, mediaData, API_BASE_URL} from '../../store.js'
    export let locationType;
    import { onMount, onDestroy } from 'svelte';
    import axios from 'axios'; 
    export let constructorObject;
    export let editObject;
    export let editExerciseID;
    let uploading = false;
    let exerciseData;
    let imageUploading = false;
  
    const resetSelectedExercise = () => {
      selectedExercise.set(null);
    };
  
    const exerciseHeader = writable("");
    const slideNameInput = writable("");
    const uploadedImages = writable([]);
  
    // const classNumber = 1;
    // const slideNumber = 1;
    // const slideSection = 1;
    // const slideCourse = 1;
    const assignAward = "false";
    const badgeDetails = null;


    const uploadFileIcon = `<svg viewBox="0 0 24 24" fill="none">
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#1C274C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C12.2106 2.25 12.4114 2.33852 12.5535 2.49392L16.5535 6.86892C16.833 7.17462 16.8118 7.64902 16.5061 7.92852C16.2004 8.20802 15.726 8.18678 15.4465 7.88108L12.75 4.9318V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V4.9318L8.55353 7.88108C8.27403 8.18678 7.79963 8.20802 7.49393 7.92852C7.18823 7.64902 7.16698 7.17462 7.44648 6.86892L11.4465 2.49392C11.5886 2.33852 11.7894 2.25 12 2.25Z" fill="#1C274C"/>
</svg>`;


const plusIcon = `
<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#ffffff">
            <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;


const returnBtn = `<svg style="width: 100%; height: 100%" viewBox="0 0 48 48" fill="none" >
<path d="M12.9998 8L6 14L12.9998 21" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

  const minusIcon = `<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-518.000000, -1089.000000)" fill="#ffffff">
            <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;

  const uploadIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#ffffff"/>
</svg>`;
  
    function initializeExistingData(data) {
      slideNameInput.set(data.slideName || "Выполните задание");
      exerciseHeader.set(data.contents[0].textContent || "");
      uploadedImages.set(data.contents[0].imageArray || []);
      mediaData.set(data.contents[0].mediaData || {});
    }
  
    if (existingData) {
      initializeExistingData(existingData);
    }
  
    function handleFileInputChange(event) {
      imageUploading = true;
    const file = event.target.files[0];
    if (file) {
        const formData = new FormData();
        formData.append('slide_image', file);
        formData.append('token', $userStore.token);

        axios.post(`${$API_BASE_URL}/uploadImage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            if (response.data.status === 'success') {
                uploadedImages.update(images => [...images, response.data.url]);
                imageUploading = false;
            } else {
                console.error('Image upload failed:', response.data.message);
            }
        })
        .catch(error => {
            console.error('Error uploading image:', error);
        });
    }
}

    function removeImage(index) {
      uploadedImages.update(images => images.filter((_, i) => i !== index));
    }
  
    function submitExercise() {
      uploading = true;
       exerciseData = {
        isExercise: "true",
        token: $userStore.token,
        exerciseID: editExerciseID ? editExerciseID : "",
        classNumber: constructorObject.classNumber,
        slideNumber: constructorObject.slideIndex,
        slideSection: constructorObject.section,
        slideCourse: constructorObject.courseId,
        slideName: get(slideNameInput) || "Выполните задание",
        isPassed: "false",
        contents: [{
          id: 1,
          textContent: get(exerciseHeader),
          imageArray: get(uploadedImages),
          role: "exercise",
          type: "image",
          customBackground: "#C20B03",
          additionalField: "",
          ...get(mediaData),
          ...(assignAward === "true" ? { "assign_award": "true" } : {}),
          ...(badgeDetails ? { "badgeDetails": badgeDetails } : {}),
        }]
      };



     
      if (locationType === "builder") {
    axios.post(`${$API_BASE_URL}/uploadExercise`, exerciseData)
    .then(response => {
      console.log(response.data);
      if (response.data.result == 'success') {
        trackContentUpload.update(value => !value);
        uploading = false;
      } else {
        console.error('Failed to upload exercise:', response.data.message);
      }
    })
    .catch(error => console.error('Error uploading exercise:', error));
  } else if (locationType == "edit") {
    axios.post(`${$API_BASE_URL}/editExercise`, exerciseData)
    .then(response => {
      console.log(response.data);
      if (response.data.result == 'success') {
        trackContentUpload.update(value => !value);
        uploading = false;
      } else {
        console.error('Failed to upload exercise:', response.data.message);
      }
    })
    .catch(error => console.error('Error uploading exercise:', error));
  }
    }

    const unsubscribe = mediaData.subscribe(store => {
        exerciseData = {
            ...exerciseData,
            chars: store.chars,
            test: store.test,
            hasChar: store.hasChar,
            hasPdf: store.hasPdf,
            hasVideo: store.hasVideo,
            videoLink: store.videoLink,
            isListening: store.isListening,
            audioLink: store.audioLink,
            pdfLink: store.pdfLink,
            hasImage: store.hasImage,
            imageLink: store.imageLink,
        };
    });

    // Cleanup subscription on component destroy
    onDestroy(() => {
        unsubscribe();
    });

    let isMobile = false;

if (typeof window !== "undefined") {
  isMobile = window.innerWidth < 800;
  window.addEventListener("resize", () => {
    isMobile = window.innerWidth < 800;
  });
}
  
  </script>
  
  <style>
    .exercise-construction-main-wrap {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
    .user--class-items-exercise-image-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
    }
    .user--class-exercise-image-wrap-inside {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .image-preview {
      width: 300px;
      height: 300px;
      object-fit: cover;
      margin: 0 auto;
      margin-block: 30px;
      border-radius: 30px;
    }
    .controls {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  .return-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .inner-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0; 
    }
    .inner-text {
      overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; 
  display: inline-block;
  flex-grow: 1; 
    }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  </style>
  
  <div class="exercise-construction-main-wrap">
    {#if !editObject}  
    <div class="return-btn" on:click={resetSelectedExercise}>{@html returnBtn}</div>
    {/if}
    <input class="user--class-exercise-header-slidename exercise-builder-header" type="text" bind:value={$slideNameInput} placeholder="Название задания" />
    <input class="user--class-exercise-header user--class-exercise-header-main exercise-builder-header" type="text" bind:value={$exerciseHeader} placeholder="Инструкция к заданию" />
  
    <div class="user--class-items-exercise-image-wrap">
      {#each $uploadedImages as imageSrc, index}
        <div class="user--exercise-image-wrapper">
          <div class="image-loader big-image-loader"></div>
          <img src={imageSrc} alt="Exercise Image" class="user--exercise-content-image image-preview" on:load={event => event.target.previousElementSibling.style.display = 'none'} />
          <button class="user--class-generic-button-3-tiny" on:click={() => removeImage(index)}><div class="inner-icon">{@html minusIcon}</div><span class="inner-text">Изображение</span></button>
        </div>
      {/each}
    </div>
  
    <div class="user--class-submit-fileinput-wrapper">
      <input type="file" id="file-upload" class="user--class-exercise-file-upload" style="display: none;" on:change={handleFileInputChange} />
      <label for="file-upload" class="user--class-submit-fileinput-icon">{@html uploadFileIcon}</label>
      {#if imageUploading}
        <div class="loading-huge jello-horizontal"></div>
      {:else}
      <button style="margin-block-start: 0px {isMobile ? 'width: 100%' : ''}" class="user--class-generic-button-3" on:click={() => document.getElementById(`file-upload`).click()}><div class="inner-icon">{@html plusIcon}</div><span class="inner-text">Изображение</span></button>
      {/if}

    </div>
  
    <div class="controls">
      {#if uploading}
        <div class="loading-huge jello-horizontal"></div>
      {:else}
      <button class="user--class-generic-button-3 user--class-generic-button-3-h" on:click={submitExercise}><div class="inner-icon">{@html uploadIcon}</div><span class="inner-text">{editObject ? 'Обновить' : 'Загрузить'}</span></button>
      {/if}
    </div>
  </div>
  