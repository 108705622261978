<script>
    import { writable, derived, get } from 'svelte/store';
    import { sendSocketEvent } from "../websocket.js";
    import {userStore, highlightAnswer} from '../store.js';
    import { onMount } from "svelte";
    import Fireworks from "./Fireworks.svelte";

    const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;
    // IMPORTANT: isAllCorrect IS NOT BEING UPDATED WITH A CORRECT VALUE
    let placeholderIcon = `<svg style="width: 50px; height: 30px" viewBox="0 0 169 56" fill="none">
<path d="M10 46C10 46 43.5331 10 63.284 10C83.035 10 83.035 46 109.514 46C135.993 46 159 10 159 10" stroke="white" stroke-width="20" stroke-linecap="round"/>
</svg>`;
    let isAllCorrect = writable(false);
    export let slideData;
    export let exerciseID;
    export let location; 
    export let completedData;

  
    let hintExpanded = writable(false);

function expandHint() {
  hintExpanded.set(!$hintExpanded);
}


// Initialize sequenceAnswers and answerFeedback based on slideData structure
let sequenceAnswers = writable(
  slideData.answer.map((block, blockIndex) => {
    // console.log(`[DEBUG] Processing block at index ${blockIndex}:`, block);

    if (Array.isArray(block)) {
      // Handle blocks with multiple inputs (arrays of arrays)
      const processedBlock = block.map((question, questionIndex) => {
        // console.log(`[DEBUG] Processing question at index ${questionIndex} in block ${blockIndex}:`, question);

        if (Array.isArray(question)) {
          // If the question is an array, create placeholders for its length
          const questionPlaceholders = new Array(question.length).fill('');
          // console.log(`[DEBUG] Created placeholders for question (array):`, questionPlaceholders);
          return questionPlaceholders;
        } else {
          // If the question is not an array, assume single input
          // console.log(`[DEBUG] Question is not an array. Creating single placeholder.`);
          return [''];
        }
      });

      // console.log(`[DEBUG] Processed block at index ${blockIndex}:`, processedBlock);
      return processedBlock;
    } else {
      // Handle single-input blocks
      // console.log(`[DEBUG] Block is not an array. Creating single placeholder.`);
      return [''];
    }
  })
);

// Debugging the final structure
/* 
sequenceAnswers.subscribe(value => {
  console.log('[DEBUG] Final sequenceAnswers structure:', JSON.stringify(value, null, 2));
});
*/



let answerFeedback = writable(
  slideData.answer.map(block =>
    Array.isArray(block)
      ? block.map(question => new Array(question.length).fill(''))
      : new Array(1).fill('')
  )
);

let submitted = writable(false);

$: if ($highlightAnswer && slideData && slideData.answer && $highlightAnswer.id === exerciseID) {
  const eventAnswer = $highlightAnswer.answer;
  const blockIndex = $highlightAnswer.blockIndex;
  const questionIndex = $highlightAnswer.questionIndex || 0;
  const inputIndex = $highlightAnswer.inputIndex || 0;

  answerFeedback.update(fb => {
    if (
      slideData.answer[blockIndex] &&
      slideData.answer[blockIndex][questionIndex] &&
      slideData.answer[blockIndex][questionIndex][inputIndex]
    ) {
      fb[blockIndex][questionIndex][inputIndex] =
        eventAnswer === slideData.answer[blockIndex][questionIndex][inputIndex].toLowerCase()
          ? 'correct'
          : 'incorrect';
    }
    return fb;
  });

  handleInput(blockIndex, questionIndex, inputIndex, eventAnswer);
}


// Handle user input for each question in the sequence and send WebSocket event
function handleInput(blockIndex, questionIndex, inputIndex, value) {
  sequenceAnswers.update(answers => {
    
    if (
      answers[blockIndex] &&
      answers[blockIndex][questionIndex] &&
      answers[blockIndex][questionIndex][inputIndex] !== undefined
    ) {
    const updatedAnswers = answers.map((block, bIndex) => 
      bIndex === blockIndex
        ? block.map((question, qIndex) =>
            qIndex === questionIndex
              ? question.map((input, iIndex) =>
                  iIndex === inputIndex ? value : input
                )
              : question
          )
        : block
    );

    const eventDetails = {
      actionName: {
        kind: "sequence", 
        blockIndex, 
        questionIndex, 
        inputIndex, 
        answer: value, 
        id: exerciseID, 
      },
      isAllCorrect: $isAllCorrect, 
      answers: updatedAnswers.map(block =>
  Array.isArray(block)
    ? block.map(question =>
        question.map(ans => (ans ? ans.toLowerCase() : ''))
      )
    : block.map(ans => (ans ? ans.toLowerCase() : ''))
),
    };

    // Uncomment for debugging
    // console.log('[DEBUG] Sending WebSocket Event:', eventDetails);

    sendSocketEvent('studentAnswerDynamic', eventDetails);

    return updatedAnswers;
    } else {
      console.error(
        `Invalid indices in handleInput`
      );
      return answers; 
    }
 
    
  });
}

// Submit answers and send the socket event
function submitAnswers() {
 

  const correctAnswers = slideData.answer.map(block =>
    Array.isArray(block)
      ? block.map(question =>
          Array.isArray(question)
            ? question.map(ans => ans ? ans.toLowerCase() : null)
            : [question ? question.toLowerCase() : null] // Handle non-array question case
        )
      : [block ? block.toLowerCase() : null] // Handle non-array block case
  );

  const userAnswers = get(sequenceAnswers).map(block =>
    Array.isArray(block)
      ? block.map(question =>
          Array.isArray(question)
            ? question.map(ans => ans ? ans.toLowerCase() : null)
            : [question ? question.toLowerCase() : null] // Handle non-array question case
        )
      : [block ? block.toLowerCase() : null] // Handle non-array block case
  );

  const { totalQuestions, totalScore } = checkSequenceAnswers(correctAnswers, userAnswers);
  isAllCorrect.set(totalScore === totalQuestions * 100);

  // Mark the sequence as submitted
  submitted.set(true);

  // Determine the correct endpoint
  let endpoint;
  if (location === "revision") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "homework") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "classroom") {
    endpoint = "exerciseCompleted";
  }

  const eventDetails = {
      actionName: {
        exerciseID: exerciseID,
        isCorrect: $isAllCorrect ? 'true' : 'false', // Convert boolean to string
        isCreative: false,
        partialScore: totalScore
      },
      answers: userAnswers, // User's answers
      isAllCorrect: $isAllCorrect ? 'true' : 'false' // Whether all answers are correct
    };

  // Send the event with the necessary data
  sendSocketEvent(endpoint, eventDetails);

  


  
}


const allAnswered = derived(sequenceAnswers, $sequenceAnswers => {
  if (!$sequenceAnswers.length) return false; // Return false for empty/uninitialized state
  // console.log('[DEBUG] $sequenceAnswers in allAnswered:', JSON.stringify($sequenceAnswers, null, 2));
  return $sequenceAnswers.flatMap(block =>
    Array.isArray(block) ? block.flat() : block
  ).every(answer => answer === null || (typeof answer === 'string' && answer.trim() !== ''));
});


/* 
// Populate answers and feedback from completed data if available
onMount(() => {
  if (completedData && completedData.studentAnswer != null) {
    try {
      const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
      if (Array.isArray(parsedStudentAnswer)) {
      // Example if each 'answer' is itself an array
parsedStudentAnswer.forEach((blockAnswers, blockIndex) => {
  blockAnswers.forEach((questionAnswers, questionIndex) => {
    questionAnswers.forEach((subAnswer, answerIndex) => {
      handleInput(blockIndex, questionIndex, answerIndex, subAnswer);
    });
  });
});


        // Update feedback based on pre-filled answers
        answerFeedback.update(fb => {
          const ua = get(sequenceAnswers);
          for (let blockIndex = 0; blockIndex < slideData.answer.length; blockIndex++) {
            for (let questionIndex = 0; questionIndex < slideData.answer[blockIndex].length; questionIndex++) {
              if (
  ua[blockIndex][questionIndex] &&
  ua[blockIndex][questionIndex].toLowerCase() ===
    slideData.answer[blockIndex][questionIndex].toLowerCase()
) {
  fb[blockIndex][questionIndex] = 'correct';
} else {
  fb[blockIndex][questionIndex] = 'incorrect';
}

            }
          }
          return fb;
        });

        submitted.set(true);
      }
    } catch (error) {
      console.error("Error parsing completedData studentAnswer:", error);
    }
  }
});
*/ 

onMount(() => {
  if (completedData && completedData.studentAnswer != null) {
    try {
      const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
      if (Array.isArray(parsedStudentAnswer)) {
  parsedStudentAnswer.forEach((blockAnswers, blockIndex) => {
    if (!Array.isArray(blockAnswers)) {
      // console.error(`[DEBUG] Invalid blockAnswers at blockIndex=${blockIndex}:`, blockAnswers);
      return;
    }
    blockAnswers.forEach((questionAnswers, questionIndex) => {
      if (!Array.isArray(questionAnswers)) {
        // console.error(`[DEBUG] Invalid questionAnswers at blockIndex=${blockIndex}, questionIndex=${questionIndex}:`, questionAnswers);
        return;
      }
      questionAnswers.forEach((subAnswer, answerIndex) => {
        if (typeof subAnswer !== 'string') {
          // console.error(`[DEBUG] Invalid subAnswer at blockIndex=${blockIndex}, questionIndex=${questionIndex}, answerIndex=${answerIndex}:`, subAnswer);
          return;
        }
        handleInput(blockIndex, questionIndex, answerIndex, subAnswer);
      });
    });
  });



  answerFeedback.update(fb => {
  const ua = get(sequenceAnswers); // Current user answers
  // console.log("[DEBUG] Current sequenceAnswers:", JSON.stringify(ua, null, 2));
  // console.log("[DEBUG] Correct answers (slideData.answer):", JSON.stringify(slideData.answer, null, 2));

  for (let blockIndex = 0; blockIndex < slideData.answer.length; blockIndex++) {
    // console.log(`[DEBUG] Processing block at index ${blockIndex}:`, JSON.stringify(slideData.answer[blockIndex], null, 2));

    for (let questionIndex = 0; questionIndex < slideData.answer[blockIndex].length; questionIndex++) {
      const userAnswers = ua[blockIndex]?.[questionIndex];
      const correctAnswers = slideData.answer[blockIndex]?.[questionIndex];

      /* console.log(`[DEBUG] Processing question at index ${questionIndex} in block ${blockIndex}:`, {
        userAnswers,
        correctAnswers,
      });
      */

      if (Array.isArray(correctAnswers)) {
        // Multi-input question: iterate over answerIndex
        for (let answerIndex = 0; answerIndex < correctAnswers.length; answerIndex++) {
          const userAnswer = userAnswers?.[answerIndex];
          const correctAnswer = correctAnswers[answerIndex];
          /*
          console.log(`[DEBUG] Processing answer at index ${answerIndex} in question ${questionIndex}, block ${blockIndex}:`, {
            userAnswer,
            correctAnswer,
          });
          */

          if (typeof userAnswer === "string" && typeof correctAnswer === "string") {
            const isCorrect = userAnswer.toLowerCase() === correctAnswer.toLowerCase();
           // console.log(`[DEBUG] String comparison result:`, { userAnswer, correctAnswer, isCorrect });

            fb[blockIndex][questionIndex][answerIndex] = isCorrect ? "correct" : "incorrect";
          } else {
            /* 
            console.warn(`[DEBUG] Skipping feedback update due to invalid data:`, {
              blockIndex,
              questionIndex,
              answerIndex,
              userAnswer,
              correctAnswer,
            });
            */
          }
        }
      } else if (typeof correctAnswers === "string") {
        // Single-input question: compare directly
        const userAnswer = Array.isArray(userAnswers) ? userAnswers[0] : userAnswers;

        console.log(`[DEBUG] Single-input comparison:`, {
          userAnswer,
          correctAnswer: correctAnswers,
        });

        if (typeof userAnswer === "string") {
          const isCorrect = userAnswer.toLowerCase() === correctAnswers.toLowerCase();
          console.log(`[DEBUG] Single-input comparison result:`, { userAnswer, correctAnswers, isCorrect });

          fb[blockIndex][questionIndex][0] = isCorrect ? "correct" : "incorrect";
        } else {
          console.warn(`[DEBUG] Skipping feedback update due to invalid single-input data:`, {
            blockIndex,
            questionIndex,
            userAnswer,
            correctAnswers,
          });
        }
      } else {
        console.warn(`[DEBUG] Skipping feedback update: invalid data structure`, {
          blockIndex,
          questionIndex,
          userAnswers,
          correctAnswers,
        });
      }
    }
  }

  // console.log("[DEBUG] Updated feedback structure:", JSON.stringify(fb, null, 2));
  return fb;
});

 


        // Automatically set submitted to true
        submitted.set(true);
        console.log('[DEBUG] submitted store variable is set to true')
      
    } else {
  console.error("[DEBUG] parsedStudentAnswer is not a valid array:", parsedStudentAnswer);
}
    } catch (error) {
      console.error("Error parsing completedData studentAnswer:", error);
    }
  }
});


// Check sequence answers and calculate the score
function checkSequenceAnswers(correctAnswers, userAnswers) {
  let totalQuestions = 0;
  let totalScore = 0;

  for (let blockIndex = 0; blockIndex < correctAnswers.length; blockIndex++) {
    const correctAnswerBlock = correctAnswers[blockIndex];
    const userAnswerBlock = userAnswers[blockIndex];
    if (Array.isArray(correctAnswerBlock)) {
      for (let questionIndex = 0; questionIndex < correctAnswerBlock.length; questionIndex++) {
        const correctQuestion = correctAnswerBlock[questionIndex];
        const userQuestion = userAnswerBlock[questionIndex];
        for (let answerIndex = 0; answerIndex < correctQuestion.length; answerIndex++) {
          const correctAnswer = correctQuestion[answerIndex] ? correctQuestion[answerIndex].trim().toLowerCase() : '';
          const userAnswer = userQuestion[answerIndex] ? userQuestion[answerIndex].trim().toLowerCase() : '';

          if (correctAnswer) {
            totalQuestions++;
            if (correctAnswer === userAnswer) {
              totalScore += 100;
              answerFeedback.update(fb => {
                fb[blockIndex][questionIndex][answerIndex] = 'correct';
                return fb;
              });
            } else {
              answerFeedback.update(fb => {
                fb[blockIndex][questionIndex][answerIndex] = 'incorrect';
                return fb;
              });
            }
          }
        }
      }
    } else {
      const correctAnswer = correctAnswerBlock ? correctAnswerBlock.trim().toLowerCase() : '';
      const userAnswer = userAnswerBlock[blockIndex] ? userAnswerBlock[blockIndex].trim().toLowerCase() : '';

      if (correctAnswer) {
        totalQuestions++;
        if (correctAnswer === userAnswer) {
          totalScore += 100;
          answerFeedback.update(fb => {
            fb[blockIndex] = 'correct';
            return fb;
          });
        } else {
          answerFeedback.update(fb => {
            fb[blockIndex] = 'incorrect';
            return fb;
          });
        }
      }
    }
  }

  // Return the total number of questions and the total score
  return { totalQuestions, totalScore };
}

function replacePlaceholder(text) {
  return text.replace(/\[Пропуск\]/g, placeholderIcon);
}

function proceedNext() {
  sendSocketEvent('proceedSelf', '');
}
/* 
sequenceAnswers.subscribe((value) => {
  if (value && value.length > 0) {
    console.log('[DEBUG] sequenceAnswers:', JSON.stringify(value, null, 2));
  } else {
    console.log('[DEBUG] sequenceAnswers not yet initialized or empty.');
  }
});

$: if ($allAnswered !== undefined) {
  console.log('[DEBUG] allAnswered:', $allAnswered);
} else {
  console.log('[DEBUG] allAnswered is undefined.');
}
*/


  </script>
  {#if $isAllCorrect}
  <Fireworks />
  {/if}
  <div class="user--class-exercise-header">
    {@html slideData.textContent}
  </div>
  
  {#if slideData.displayTips && slideData.displayTips === "true"}
    <div class="user--exercise-multiple_sequence_sdisplay">
      {#each Array.from(new Set(slideData.answer.flatMap(block => Array.isArray(block) ? block.flat() : block))) as answer, index}
        <span class="user--exercise-multiple_sequence_hint {$answerFeedback[0][0][index] === 'correct' ? 'correct' : 'incorrect'}" data-repetitions={slideData.answer.flatMap(block => Array.isArray(block) ? block.flat() : block).filter(a => a === answer).length}>
          {answer}
        </span>
      {/each}
    </div>
  {/if}
  
  <div class="sequence-exercise">
    <div class="exercise-answer-block-multiple">
      {#each slideData.answer as block, blockIndex}
        <div class="user--exercise-block-wrapper">
          <div class="block-index-number">{blockIndex + 1}</div>
          <div class="user--class-exercise-header exercise-builder-header exercise-header-underlined">
            {@html replacePlaceholder(slideData.descriptor[blockIndex])}
          </div>
          {#if slideData.hint[blockIndex] && $hintExpanded}
          <div class="user--exercise-hint-mini">
            {slideData.hint[blockIndex]}
          </div>
        {/if}
          <div class="flex-no-gap">
            {#each block as question, questionIndex}
            <div class="user--exercise-sequence-block-wrap">
              <div class="user--class-exercise-answer-block">
                {#if Array.isArray(question)}
                  {#each question as _, answerIndex}
                    <input
                      type="text"
                      class="user--class-exercise-input"
                      placeholder="{`Ответ в месте пропуска ${questionIndex + 1}...`}"
                      bind:value={$sequenceAnswers[blockIndex][questionIndex][answerIndex]} 
                      on:input={(e) => handleInput(blockIndex, questionIndex, answerIndex, e.target.value)}
                      style="border: 3px solid {$answerFeedback[blockIndex][questionIndex][answerIndex] === 'correct' ? 'rgba(135, 194, 177, 1)' : $answerFeedback[blockIndex][questionIndex][answerIndex] === 'incorrect' ? 'rgba(255, 147, 147, 1)' : '#E7EEF6'}"
                    />
                  {/each}
                {:else}
                  <input
                    type="text"
                    class="user--class-exercise-input"
                    placeholder="{`Впишите пропущенное слово/фрагмент`}"
                    bind:value={$sequenceAnswers[blockIndex][questionIndex][0]} 
                    on:input={(e) => handleInput(blockIndex, questionIndex, 0, e.target.value)}
                    style="border: 3px solid {$answerFeedback[blockIndex][questionIndex][0] === 'correct' ? 'rgba(135, 194, 177, 1)' : $answerFeedback[blockIndex][questionIndex][0] === 'incorrect' ? 'rgba(255, 147, 147, 1)' : '#E7EEF6'}"
                  />
                {/if}
              </div>
            </div>
          {/each}
          
          </div>
        </div>
      {/each}
    </div>
  
    <div class="user--class-exercise-buttons-wrap">
      {#if $submitted}
      {#if location == "classroom"}
        <div class="submitted-icon">{@html iconSubmitted}</div>
      {:else if (location == "homework" || location == "revision")}
      <button class="user--class-generic-button-3"  on:click={(event) => {
        event.currentTarget.classList.add('inactive');
        event.currentTarget.textContent = 'Загружаю...';
        proceedNext();
      }}
      >Продолжить</button
    >
      {/if}
        {:else}
    {#if $userStore.student_status != 0}
        <button on:click={submitAnswers} class="user--class-generic-button-3 { $allAnswered ? '' : 'user--button-clicked' }">Ответить</button>
      {/if}  
      {/if}
  
      {#if slideData.hint && slideData.hint.some((hint) => hint !== "")}
      <button class="user--class-generic-button-3" on:click={expandHint}
        >Подсказка</button
      >
    {/if}
    </div>
  </div>
  



<style>
    .correct {
        border-color: rgba(135, 194, 177, 1);
    }
    .incorrect {
        border-color: rgba(255, 147, 147, 1);
    }
    .not-defined {
        border-color: rgba(192, 192, 192, 1);
    }
    .centered-div {
        width: 60px;
        height: 60px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .loading-huge {
        width: 60px;
        height: 60px;
        background: linear-gradient(45deg, #020344, #28b8d5);
        border-radius: 50%;
        position: relative;
    }
    .jello-horizontal {
        -webkit-animation: jello-horizontal 0.9s infinite both;
        animation: jello-horizontal 0.9s infinite both;
    }
    .block-index-number {
      font-size: 50px;
      font-weight: 900;
      color: #ffffff;
      width: 90%;
      height: max-content;
      text-align: left;
      margin: 0 auto;
      padding-block-start: 15px;
    }
    .submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    }
    @-webkit-keyframes jello-horizontal {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    @keyframes jello-horizontal {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    .user--exercise-item-wrapper {
        transition: border-color 0.3s;
    }
    .coloured {
       border: 2px solid #0C5CA4;
    }
    .flex-no-gap {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
</style>
