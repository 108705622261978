<script>
    import { onMount, onDestroy, tick } from 'svelte';
    import { fly, blur } from "svelte/transition";
    import ErrorBoundary from './ErrorBoundary.svelte';
    import { writable, get } from 'svelte/store';
    import TrueFalseExercise from './TrueFalseExercise.svelte'; // REMOVED ANSWER FOR 0 
    import MultipleChoiceExercise from './MultipleChoiceExercise.svelte'; // REMOVED ANSWER FOR 0
    import ImageExercise from './ImageExercise.svelte'; // REMOVED ANSWER FOR 0
    import MatchExercise from './MatchExercise.svelte'; // REMOVED ANSWER FOR 0
    import SequenceExercise from './Sequence.svelte'; // REMOVED ANSWER FOR 0
    import ParticleExercise from './ParticleExercise.svelte'; // REMOVED ANSWER FOR 0
    import TableListExercise from './TableListExercise.svelte'; // REMOVED ANSWER FOR 0
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css'; // CSS assets
    import audiojs from 'audio.js';
    import 'audio.js/dist/audio-js.css'; // CSS assets

    let pdfContainer; // Linked to the div with `bind:this`
    let loader;       // Linked to the loader div
    // import {Audio} from 'svelte-audio';
    //import {AudioPlayer} from 'svelte-mp3';
  //  import WaitingComponent from './WaitingComponent.svelte';

  
    export let slideData;
    export let exerciseID;
    export let location; // location -- out of 3 possible values: 'revision', 'homework', or 'classroom'
    export let completedData;
    let loading = writable(true);
    let player;
    let videoElement;
    let playerAudio;
    let audioElement;

    function initializePlayer() {
    if (videoElement) {
      console.log("Video element found and source is available.");

      if (player) {
        console.log("Destroying existing player instance.");
        player.dispose();
      }

      player = videojs(videoElement, {
        controls: true,
        autoplay: false,
        preload: 'auto',
        fluid: true,
      });

      console.log("Player initialized successfully.");
    } else {
      console.log("Video element or source not found.");
    }
  }

  function initializeAudioPlayer() {
  if (audioElement) {
    console.log("Audio element found and source is available.");

    if (playerAudio) {
      console.log("Destroying existing audio player instance.");
      playerAudio.dispose();
    }

    playerAudio = audiojs(audioElement, {
      controls: true,
      autoplay: false,
      preload: 'auto',
      fluid: true,
    });

    console.log("Audio player initialized successfully.");
  } else {
    console.log("Audio element or source not found.");
  }
}


function pdfSlide() {
  // console.log("inside the pdfSlide function");

  if (!pdfContainer || !loader) {
    // console.error("PDF container or loader is not linked.");
    return;
  }

  const data = slideData.contents[0];
  if (!data.pdfLink) {
    // console.error("PDF link is not provided.");
    if (loader) loader.remove();
    if (pdfContainer) pdfContainer.remove();
    return;
  }

  // Proceed with loading the PDF
  const loadingTask = pdfjsLib.getDocument(data.pdfLink);

  loadingTask.promise
    .then((pdfDocument) => {
      // console.log("Loaded PDF Document");
      pdfContainer.innerHTML = ""; 
      pdfContainer.style.height = "auto";

      let pdfHeight = 0;
      const scale = 1;
      const devicePixelRatio = window.devicePixelRatio || 1;

      for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
        pdfDocument.getPage(pageNumber).then((page) => {
          const viewport = page.getViewport({ scale });
          const containerWidth = pdfContainer.offsetWidth;
          const pageWidthScale = containerWidth / viewport.width;
          const scaledViewport = page.getViewport({
            scale: pageWidthScale * devicePixelRatio,
          });

          const canvas = document.createElement("canvas");
          canvas.width = scaledViewport.width;
          canvas.height = scaledViewport.height;

          const canvasContext = canvas.getContext("2d");
          canvasContext.imageSmoothingEnabled = false;
          const renderContext = {
            canvasContext,
            viewport: scaledViewport,
          };

          page.render(renderContext).promise.then(() => {
            canvas.style.width = `${scaledViewport.width / devicePixelRatio}px`;
            canvas.style.height = `${scaledViewport.height / devicePixelRatio}px`;
            canvas.style.borderRadius = "25px";

            pdfContainer.appendChild(canvas);
            pdfHeight += scaledViewport.height / devicePixelRatio;

            if (pdfContainer.childElementCount === pdfDocument.numPages) {
              pdfContainer.style.height = `${pdfHeight}px`;
              if (loader) loader.remove();
            }
          });
        });
      }
    })
    .catch((error) => {
      if (loader) loader.innerText = "Упс...";
    });
}



      
    onMount(async() => {
      loading.set(false);
      if (slideData.contents[0].hasVideo) {
        initializePlayer();
      }
      if (slideData.contents[0].isListening) {
        initializeAudioPlayer();
      } 
      if (
    slideData.contents[0].hasPdf === true ||
    slideData.contents[0].hasPdf === "true"
  ) {
    await tick();

    if (pdfContainer && loader) {
      pdfSlide();
    } else {
    }
  }
    });

    onDestroy(() => {
    if (player) {
      player.dispose(); // Clean up the Video.js instance on component destroy
    }
    if (playerAudio) {
      playerAudio.dispose(); // Clean up the Audio.js instance on component destroy
    }
  });
  
    // data is already fetched? 
    function fetchExerciseData() {
      sendSocketEvent('loadExercise', { exerciseID });
      // Assume the socket event will fetch data and update slideData
      loading.set(false);
    }
  </script>
  
  {#if $loading}
  <div class="loading-huge jello-horizontal"></div>
{:else}
  <div class="user--class-exercise-wrap" in:blur={{duration: 500}}>
    <div class="user--class-exercise-main-header">
      {@html slideData.slideName}
    </div>
    {#if slideData.contents[0].hasImage && slideData.contents[0].imageLink}
      <img src={slideData.contents[0].imageLink} alt="Exercise Image" class="user--class-started-image">
    {/if}
    
    
    {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
    <audio 
      bind:this={audioElement} 
      id="audio-js"
      class="audio-js"
      controls
      preload="auto"
    >
      <source src={slideData.contents[0].audioSrc} type="audio/mp3" />
      Аудио не поддерживается
    </audio>
  {/if}
    
    {#if (slideData.contents[0].hasVideo && slideData.contents[0].hasVideo === true) || slideData.contents[0].hasVideo === "true"}
      <video 
      bind:this={videoElement} 
      id="video-js"
      class="video-js vjs-default-skin custom-modifications"
      controls
      preload="auto"
    >
      <source src={slideData.contents[0].videoSrc} type="video/mp4" />
    Видео не поддерживается
    </video>
    {/if}


    {#if (slideData.contents[0].hasPdf && slideData.contents[0].hasPdf === true) || slideData.contents[0].hasPdf === "true"}
    <div bind:this={pdfContainer} class="pdf-container slide-iframe-pdf">
      <div bind:this={loader} class="loading-huge spin"></div>
    </div>
  {/if}
  

  {#if slideData?.contents[0]?.type === 'truefalse'}
  <ErrorBoundary componentName="TrueFalseExercise">
    <TrueFalseExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'multiplechoice_multiple' || slideData?.contents[0]?.type === 'multiplechoice'}
  <ErrorBoundary componentName="MultipleChoiceExercise">
    <MultipleChoiceExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'match'}
  <ErrorBoundary componentName="MatchExercise">
    <MatchExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'image'}
  <ErrorBoundary componentName="ImageExercise">
    <ImageExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'sequence'}
  <ErrorBoundary componentName="SequenceExercise">
    <SequenceExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'particle'}
  <ErrorBoundary componentName="ParticleExercise">
    <ParticleExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'tablelist'}
  <ErrorBoundary componentName="TableListExercise">
    <TableListExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  </ErrorBoundary>
{:else if slideData?.contents[0]?.type === 'waiting'}
  <ErrorBoundary componentName="WaitingComponent">
    <WaitingComponent slideData={slideData.contents[0]} />
  </ErrorBoundary>
{/if}
  </div>
{/if}

  
  <style>
    .centered-div {
      width: 60px;
      height: 60px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .loading-huge {
      width: 60px;
      height: 60px;
      background: linear-gradient(45deg, #020344, #28b8d5);
      border-radius: 50%;
      position: relative;
    }
    .jello-horizontal {
      -webkit-animation: jello-horizontal 0.9s infinite both;
      animation: jello-horizontal 0.9s infinite both;
    }
    @-webkit-keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    @keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
  </style>