<script>
 import { fly, blur } from "svelte/transition";
 import { onMount, onDestroy } from 'svelte';
    import { writable, get } from 'svelte/store';
    import {translate} from "../translation";
    // ADD MEDIA AND ADDITIONAL OPTIONS FOR COMPONENTS
    import TrueFalseExercise from './constructor/TrueFalseExercise.svelte'; // d-revised
    import MultipleChoiceExercise from './constructor/MultipleChoiceExercise.svelte'; // d-revised 
    import ImageExercise from './constructor/ImageExercise.svelte'; // d-revised
    import MatchExercise from './constructor/MatchExercise.svelte'; // d-revised
    import SequenceExercise from './constructor/SequenceExercise.svelte'; // d-revised
    import ParticleExercise from './constructor/ParticleExercise.svelte'; //  d-revised
    import TableListExercise from './constructor/TableListExercise.svelte'; // d-revised
    import ExerciseExtension from "./constructor/ExerciseExtension.svelte";
    import AwardOptionsCentre from "./constructor/AwardOptionsCentre.svelte"; 
    import { setDefaultValues } from "../store.js";
  // import App from "./App.svelte";
  // import Auth from "./Auth.svelte";
  // import Constructor from "./Constructor.svelte";
  // import CourseCreator from "./CourseCreator.svelte";
    export let returnToSelect;  
    let additionalToggled = writable(false);
    function toggleAdditional() {
    additionalToggled.update(value => !value);
  }

  export let locationType;
  export let constructorObject;
  export let slideData;
  export let exerciseID;

  // console.log('CreateExercise component mounted. slideData passed to the component:', slideData);
   const exerciseSticker = `<svg style="width: 100%; height: 100%" viewBox="0 0 32 32" version="1.1"><defs></defs><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"><g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-412.000000, -99.000000)" fill="#5c5c5c"><path d="M442,124 C442,125.104 441.073,125.656 440,126 C440,126 434.557,127.515 429,128.977 L429,104 L440,101 C441.104,101 442,101.896 442,103 L442,124 L442,124 Z M427,128.998 C421.538,127.53 416,126 416,126 C414.864,125.688 414,125.104 414,124 L414,103 C414,101.896 414.896,101 416,101 L427,104 L427,128.998 L427,128.998 Z M440,99 C440,99 434.211,100.594 428.95,102 C428.291,102.025 427.627,102 426.967,102 C421.955,100.656 416,99 416,99 C413.791,99 412,100.791 412,103 L412,124 C412,126.209 413.885,127.313 416,128 C416,128 421.393,129.5 426.967,131 L428.992,131 C434.612,129.5 440,128 440,128 C442.053,127.469 444,126.209 444,124 L444,103 C444,100.791 442.209,99 440,99 L440,99 Z" id="book-album" sketch:type="MSShapeGroup"></path></g></g></svg>`;
   const selectedExercise = writable(null);
   const returnBtn = `<svg style="width: 100%; height: 100%" viewBox="0 0 48 48" fill="none" >
<path d="M12.9998 8L6 14L12.9998 21" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const plusIcon = `
<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#ffffff">
            <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;
const minusIcon = `<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-518.000000, -1089.000000)" fill="#ffffff">
            <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;
   const exercises = [
    {
      id: "exercise_test_truefalse",
      type: "truefalse",
      name: "Верно/Неверно",
      desc: "Идеально подходит для заданий, где есть всего два варианта ответа - верно или неверно"
    },
    {
      id: "exercise_test_multiple",
      type: "multiplechoice_multiple",
      name: "Тесты",
      desc: "Идеально подходит для заданий, где ученик должен выбрать правильный ответ из различных вариантов"
    },
    {
      id: "exercise_test_fillin_sequence",
      type: "fillin_multiple_sequence",
      name: "Заполнить пропуски",
      desc: "Идеально подходит для заданий, где учащийся должен заполнить пропущенное слово или текст"
    },
    {
      id: "exercise_test_match",
      type: "match",
      name: "Задание на визуальное соотношение",
      desc: "Идеально подходит для заданий, где учащийся должен ответить на вопрос с изображением и его описанием"
    },
    {
      id: "exercise_test_tablelist",
      type: "tablelist",
      name: "Задание на текстовое соотношение",
      desc: "Идеально подходит для заданий, где учащийся должен ответить на вопрос с изображением и его описанием"
    },
    {
      id: "exercise_test_image",
      type: "image",
      name: "Задание в форме изображения",
      desc: "Идеально подходит для заданий, где нужно использовать изображение или PDF файл как инструкцию к заданию"
    },
    {
      id: "exercise_test_particle",
      type: "particle",
      name: "Соединение частей в целое",
      desc: "Идеально подходит для заданий, где нужно соединить части предложений или соответствующие части"
    }
  ];
  function selectExercise(exercise) {
    selectedExercise.set(exercise);
    setDefaultValues();
  }

  onMount(() => {
    if (slideData !== undefined) {
    selectedExercise.set(slideData.contents[0]);
  }
  });
 

</script>
{#if $selectedExercise === null}
<div class="constructor-wrapper" in:blur={{duration: 500}}>
  <div class="return-btn" on:click={() => {returnToSelect(null)}}>{@html returnBtn}</div>

<div class="user--class-exercise-header">{translate("Выберите тип упражнения", "Select the kind of exercise you want to create", "选择练习类型")}</div>


<div class="constructor--exercise-list-main">
    {#each exercises as exercise}
      <div class="constructor--exercise-list-option-wrapper" id={exercise.id} data-role="exercise" data-isexercise="true" data-type={exercise.type} on:click={() => selectExercise(exercise)}>
        <div class="constructor--exercise-custom-icon">{@html exerciseSticker}</div>
        <div class="constructor--exercise-list-option-name">{exercise.name}</div>
        <div class="constructor--exercise-list-option-desc constructor--exercise-list-option-desc-small">{exercise.desc}</div>
      </div>
    {/each}
  </div>
</div>
{:else}
  {#if $selectedExercise.type === "truefalse"}
    <TrueFalseExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''}/>
    {:else if $selectedExercise.type === "multiplechoice_multiple" }
    <MultipleChoiceExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />
{:else if $selectedExercise.type === "fillin_multiple_sequence"}
    <SequenceExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />   
    {:else if $selectedExercise.type === "match"}
    <MatchExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />
    {:else if $selectedExercise.type === "tablelist"}
    <TableListExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />
   {:else if $selectedExercise.type === "image"}
    <ImageExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />
  {:else if $selectedExercise.type === "particle"}
    <ParticleExercise {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />
  {/if}
  <button
  class="user--class-generic-button-3"
  on:click={() =>
    toggleAdditional()}
  ><div class="inner-icon">{#if $additionalToggled} {@html minusIcon} {:else} {@html plusIcon} {/if}</div><span class="inner-text">Вложения и опции</span></button
>
{#if $additionalToggled} 
  <ExerciseExtension {selectedExercise} locationType = "{locationType}" constructorObject={constructorObject} editObject={slideData !== undefined ? slideData : ''} editExerciseID={exerciseID !== undefined ? exerciseID : ''} />
<AwardOptionsCentre {selectedExercise}  />
{/if}
{/if}


<style>
    .constructor-wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
    }
    .return-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .constructor--exercise-custom-icon {
        width: 40px;
        height: 40px;
    }
    .inner-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0; 
    }
    .inner-text {
      overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; 
  display: inline-block;
  flex-grow: 1; 
    }
</style>