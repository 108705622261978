<script>
    import { writable } from 'svelte/store';

// Reactive state using Svelte stores
const hasError = writable(false);
const errorDetails = writable(null);

export let componentName = 'Component';
export let fallbackMessage = 'An error occurred.';

// Reset the error state
const resetError = () => {
  hasError.set(false);
  errorDetails.set(null);
};

// Handle the error and update the store
function handleError(e) {
  hasError.set(true);
  errorDetails.set(e);
  console.error(`[ErrorBoundary] Error in ${componentName}:`, e);
}
</script>

{#if $hasError}
<div class="error-boundary">
  <h2>Произошла ошибка в компоненте {componentName}</h2>
  <p>{fallbackMessage}</p>
  <p><strong>Error Details:</strong> {$errorDetails?.message || $errorDetails}</p>
  <div class="user--class-generic-button-3-tiny" on:click={resetError}>Перезагрузить</div>
  <div
    class="user--class-generic-button-3-tiny"
    on:click={() =>
      console.log(`[ErrorBoundary] Reporting issue:`, {
        componentName,
        error: $errorDetails,
      })
    }
  >
    Отправить разработчикам
  </div>
</div>
{:else}
<!-- Wrap the slot content in a div and attach the error listener -->
<div class="div-styling" on:error={handleError}>
  <slot />
</div>
{/if}

  <style>
    .div-styling {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .error-boundary {
      padding: 20px;
      border-radius: 30px;
      background-color: #ffeeee;
      color: #000;
      font-weight: 700;
    }
  </style>
  