<script>
    import { onMount } from 'svelte';
    
  </script>
  
  <style>
    /* The overlay should cover the entire viewport and sit on top */
    .fireworks-overlay {
      position: fixed; /* Position fixed ensures it's always on top */
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none; /* Allow interaction with elements behind */
      z-index: 9999; /* Ensure it's above all other elements */
    }
  
    /* Optional: You might want to add a fade-in effect or animation to the fireworks */
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  
    .fireworks-overlay {
      width: 100%;
      height: 100%;
      animation: fadeIn 2s ease-in-out;
    }
  </style>
  

  
  <!-- Fireworks animation overlay -->
  <div class="fireworks-overlay">   
    <dotlottie-player
    id="success-animated"
    autoplay
    repeat="false"
    mode="normal"
    src="./assets/fireworks.lottie"
    style="width: 100vw; height: 100vh"
  >
  </dotlottie-player>
</div>
  