<script>
    import { writable, get } from 'svelte/store';
    export let selectedExercise;
    export let existingData = null;
    import { onMount, onDestroy } from 'svelte';
    import {userStore, trackContentUpload, mediaData, API_BASE_URL} from '../../store.js'
    export let locationType;
    import axios from 'axios'; 
    export let constructorObject;
    export let editObject;
    export let editExerciseID;
    let uploading = false;
    let imageUploading = false;
    let exerciseData; 
  
    const resetSelectedExercise = () => {
      selectedExercise.set(null);
    };
  
    const exerciseHeader = writable("");
    const slideNameInput = writable("");
    const particles = writable([]);


    const plusIcon = `
<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#ffffff">
            <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;


const returnBtn = `<svg style="width: 100%; height: 100%" viewBox="0 0 48 48" fill="none" >
<path d="M12.9998 8L6 14L12.9998 21" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

  const minusIcon = `<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-518.000000, -1089.000000)" fill="#ffffff">
            <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;

  const uploadIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#ffffff"/>
</svg>`;
  
  
    // const classNumber = 1;
    // const slideNumber = 1;
    // const slideSection = 1;
    // const slideCourse = 1;
    const assignAward = "false";
    const badgeDetails = null;
    const deleteIcon = `<svg style="width: 100%; height: 100%" viewBox="0 -5 32 32" version="1.1"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"><g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-516.000000, -1144.000000)" fill="#000000"><path d="M538.708,1151.28 C538.314,1150.89 537.676,1150.89 537.281,1151.28 L534.981,1153.58 L532.742,1151.34 C532.352,1150.95 531.718,1150.95 531.327,1151.34 C530.936,1151.73 530.936,1152.37 531.327,1152.76 L533.566,1154.99 L531.298,1157.26 C530.904,1157.65 530.904,1158.29 531.298,1158.69 C531.692,1159.08 532.331,1159.08 532.725,1158.69 L534.993,1156.42 L537.232,1158.66 C537.623,1159.05 538.257,1159.05 538.647,1158.66 C539.039,1158.27 539.039,1157.63 538.647,1157.24 L536.408,1155.01 L538.708,1152.71 C539.103,1152.31 539.103,1151.68 538.708,1151.28 L538.708,1151.28 Z M545.998,1162 C545.998,1163.1 545.102,1164 543.996,1164 L526.467,1164 L518.316,1154.98 L526.438,1146 L543.996,1146 C545.102,1146 545.998,1146.9 545.998,1148 L545.998,1162 L545.998,1162 Z M543.996,1144 L526.051,1144 C525.771,1143.98 525.485,1144.07 525.271,1144.28 L516.285,1154.22 C516.074,1154.43 515.983,1154.71 515.998,1154.98 C515.983,1155.26 516.074,1155.54 516.285,1155.75 L525.271,1165.69 C525.467,1165.88 525.723,1165.98 525.979,1165.98 L525.979,1166 L543.996,1166 C546.207,1166 548,1164.21 548,1162 L548,1148 C548,1145.79 546.207,1144 543.996,1144 L543.996,1144 Z" id="delete" sketch:type="MSShapeGroup"></path></g></g></svg>`;
  
    function initializeExistingData(data) {
      slideNameInput.set(data.slideName || "Выполните задание");
      exerciseHeader.set(data.contents[0].textContent || "");
      particles.set(
        data.contents[0].particleFirst.map((first, index) => ({
          first,
          second: data.contents[0].particleSecond[index] || "",
          answer: data.contents[0].answer[index] || "",
        }))
      );
      mediaData.set(data.contents[0].mediaData || {});
    }
  
    if (editObject) {
      initializeExistingData(editObject);
    } else {
      addParticle(); // Initialize with one default particle line
    }
  
    function addParticle() {
      particles.update(lines => [...lines, { first: "", second: "", answer: "" }]);
    }
  
    function removeParticle(index) {
      particles.update(lines => lines.filter((_, i) => i !== index));
    }
  
    function handleFirstInput(index, value) {
      particles.update(lines => {
        lines[index].first = value;
        return lines;
      });
    }
  
    function handleSecondInput(index, value) {
      particles.update(lines => {
        lines[index].second = value;
        return lines;
      });
    }
  
    function handleAnswerInput(index, value) {
      particles.update(lines => {
        lines[index].answer = value;
        return lines;
      });
    }
  
    function submitExercise() {
      uploading = true;
      const firstParts = [];
      const secondParts = [];
      const answers = [];
  
      get(particles).forEach(particle => {
        firstParts.push(particle.first);
        secondParts.push(particle.second);
        answers.push(particle.answer);
      });
  
      exerciseData = {
        isExercise: "true",
        token: $userStore.token,
        exerciseID: editExerciseID ? editExerciseID : "",
        classNumber: constructorObject.classNumber,
        slideNumber: constructorObject.slideIndex,
        slideSection: constructorObject.section,
        slideCourse: constructorObject.courseId,
        slideName: get(slideNameInput) || "Выполните задание",
        isPassed: "false",
        contents: [{
          id: 1,
          textContent: get(exerciseHeader),
          particleFirst: firstParts,
          particleSecond: secondParts,
          answer: answers,
          role: "exercise",
          type: "particle",
          customBackground: "#C20B03",
          additionalField: "",
          ...get(mediaData),
          ...(assignAward === "true" ? { "assign_award": "true" } : {}),
          ...(badgeDetails ? { "badgeDetails": badgeDetails } : {}),
        }]
      };
  
      console.log(exerciseData);

    
      if (locationType === "builder") {
    axios.post(`${$API_BASE_URL}/uploadExercise`, exerciseData)
    .then(response => {
      console.log(response.data);
      if (response.data.result == 'success') {
        trackContentUpload.update(value => !value);
        uploading = false;
      } else {
        console.error('Failed to upload exercise:', response.data.message);
      }
    })
    .catch(error => console.error('Error uploading exercise:', error));
  } else if (locationType == "edit") {
    axios.post(`${$API_BASE_URL}/editExercise`, exerciseData)
    .then(response => {
      console.log(response.data);
      if (response.data.result == 'success') {
        trackContentUpload.update(value => !value);
        uploading = false;
      } else {
        console.error('Failed to upload exercise:', response.data.message);
      }
    })
    .catch(error => console.error('Error uploading exercise:', error));
  }
    }


     // METADATA - 

     const unsubscribe = mediaData.subscribe(store => {
        exerciseData = {
            ...exerciseData,
            chars: store.chars,
            test: store.test,
            hasChar: store.hasChar,
            hasPdf: store.hasPdf,
            hasVideo: store.hasVideo,
            videoLink: store.videoLink,
            isListening: store.isListening,
            audioLink: store.audioLink,
            pdfLink: store.pdfLink,
            hasImage: store.hasImage,
            imageLink: store.imageLink,
        };
    });

    // Cleanup subscription on component destroy
    onDestroy(() => {
        unsubscribe();
    });
  
   
  </script>
  
  <style>
    .exercise-construction-main-wrap {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
    .controls {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
    .user--exercise-item-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }
    .user--exercise-particle-line-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

    }
    .user--exercise-particle-line-left,
    .user--exercise-particle-line-right {
      display: flex;
      align-items: center;
    }
    .user--exercise-particle-line-left-input,
    .user--exercise-particle-line-right-input {
      margin-left: 10px;
      margin-right: 10px;
    }
    /* .user--exercise-particle-answer-block {
      margin: 0 10px;
    } */
    .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  .return-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .inner-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0; 
    }
    .inner-text {
      overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; 
  display: inline-block;
  flex-grow: 1; 
    }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .icon-delete-line {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 750px) {
    .user--exercise-particle-line-wrapper {
      flex-direction: column;
    }
    .user--exercise-particle-line-left,
    .user--exercise-particle-line-right {
     width: 100%;
    }
    .user--exercise-particle-line-left-input,
    .user--exercise-particle-line-right-input {
      width: 100%;
    }
    .icon-delete-line {
      width: 40px;
      height: 40px;
      margin: 0 auto;
      
    }
  }
  </style>
  
  <div class="exercise-construction-main-wrap">
    {#if !editObject}  
    <div class="return-btn" on:click={resetSelectedExercise}>{@html returnBtn}</div>
  {/if}
    <input class="user--class-exercise-header-slidename exercise-builder-header" type="text" bind:value={$slideNameInput} placeholder="Название задания" />
    <input class="user--class-exercise-header user--class-exercise-header-main exercise-builder-header" type="text" bind:value={$exerciseHeader} placeholder="Инструкция к заданию" />
  
    <div class="user--exercise-builder-block-description">Добавьте строку с левой и правой частями:</div>
    {#each $particles as particle, index}
      <div class="user--exercise-particle-line-wrapper">
        <div class="user--exercise-particle-line-left">
          <span>{index + 1}</span>
          <input type="text" class="user--exercise-particle-line-left-input" bind:value={particle.first} placeholder="Левая часть" on:input={(e) => handleFirstInput(index, e.target.value)} />
        </div>
        <div class="user--exercise-particle-answer-block">
          <input type="text" class="user--class-exercise-match-input" placeholder="Ответ" bind:value={particle.answer} on:input={(e) => handleAnswerInput(index, e.target.value)} />
        </div>
        <div class="user--exercise-particle-line-right">
          <input type="text" class="user--exercise-particle-line-right-input" bind:value={particle.second} placeholder="Правая часть" on:input={(e) => handleSecondInput(index, e.target.value)} />
          <span>{String.fromCharCode(65 + index)}</span>
        </div>
        <div class="icon-delete-line" on:click={() => removeParticle(index)}>{@html deleteIcon}</div>
      </div>
    {/each}
  
    <div class="controls">
      <button class="user--class-generic-button-3" on:click={addParticle}><div class="inner-icon">{@html plusIcon}</div><span class="inner-text">Строка</span></button>
    </div>
  
    {#if uploading}
      <div class="loading-huge jello-horizontal"></div>
    {:else}
    <button class="user--class-generic-button-3 user--class-generic-button-3-h" on:click={submitExercise}><div class="inner-icon">{@html uploadIcon}</div><span class="inner-text">{editObject ? 'Обновить' : 'Загрузить'}</span></button>
    {/if}
  </div>
  